<script>
import api from '@/command/api'
import { Card } from 'ant-design-vue'
import TableVIew from '../../../components/TableVIew'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction } from '@/command/netTool'
export default {
  name: 'specialProduct',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    this.getDataInit()
  },

  methods: {
    getDataInit() {
      getAction('/api/config/farmGoodsCategory/list').then((result) => {
        this.records = result.data
      })
    },
    // 商城分类
    getColumns() {
      return [
        {
          dataIndex: 'name',
          align: 'left',
          title: '全部',
          width: '45%',
        },
        {
          dataIndex: 'sort',
          align: 'left',
          title: '排序',
          width: '45%',
          sorter: (a, b) => a.sort - b.sort,
        },
        {
          dataIndex: 'a1',
          align: 'center',
          width: '10%',
          title: '操作',
          tableType: 'editDelete',
          onEdit: (text, records) => {
            this.handleAddData(records)
          },
        },
      ]
    },
    delListData(id) {
      api.command.delPost
        .call(this, {
          url: '/config/farmGoodsCategory/delete',
          params: {
            id,
          },
          isPost: false,
        })
        .then((result) => {
          this.getDataInit()
        })
    },
    getDrawerForm(records) {
      const form = [
        {
          form: [
            {
              name: '分类名称',
              type: 'input',
              key: 'name',
              maxLength: 4,
            },
            {
              name: '排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                min: 0,
              },
            },
          ],
        },
        {
          title: '展示文字（图片上的正副标题）',
          form: [
            {
              name: '主标题',
              type: 'input',
              key: 'title',
            },
            {
              name: '副标题',
              type: 'input',
              key: 'subTitle',
            },
          ],
        },
        {
          form: [
            {
              name: '展示图片',
              type: 'iconUpload',
              key: 'a12',
              typeData: [
                {
                  maxLength: 1,
                  key: 'image2',
                  desc: '@2x   xhdpi <br/> 上传尺寸750*480px',
                },
                {
                  maxLength: 1,
                  key: 'image3',
                  desc: '@3x   xhdpi <br/> 上传尺寸1125*720px',
                },
              ],
            },
          ],
        },
      ]

      return form
    },
    handleAddData(records = {}) {
      let data = this.getDrawerForm(records)
      apiTool.showDrawer({
        title: '商城分类',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data,
          form: records,
        },
        success: ({ data, setHidden }) => {
          if (!(data.name && data.title && data.subTitle && data.image2 && data.image3)) {
            this.$message.warning('请完善信息！')
            return
          }
          api.command[records.id ? 'editPost' : 'create']
            .call(this, {
              url: `/config/farmGoodsCategory/${records.id ? 'update' : 'add'}`,
              params: {
                ...data,
              },
              isPost: false,
            })
            .then((result) => {
              this.getDataInit()
              setHidden()
            })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    renderRight() {
      return (
        <template slot="extra">
          <a-button onClick={this.handleAddData}>添加</a-button>
        </template>
      )
    },
  },
  render() {
    return (
      <Card title="商城分类">
        {this.renderRight()}
        <TableVIew
          showPagination={false}
          showRowSelect={false}
          showFootButton={false}
          dataSource={this.records}
          columns={this.getColumns()}
          onDelListData={this.delListData}
          onHandleAddData={this.handleAddData}
        />
      </Card>
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  margin-top: 30px;
}
</style>
